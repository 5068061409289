import React, { useRef, useEffect } from "react";
import "./../../styles/popup.css";
import CloseBtnSvg from "../../assets/close-btn.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RamadanPopup = ({ isOpen, handleClosePopup }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClosePopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClosePopup]);

  if (!isOpen) return null;

  return (
    <>
      <div className="blurry-background" onClick={handleClosePopup}></div>
      <div className="popup-container" ref={popupRef}>
        <div className="popup-close-button" onClick={handleClosePopup}>
          <img src={CloseBtnSvg} alt="Close" />
        </div>
        <div className="popup-title">
          {t("ramadanGreeting", "Ramadan Kareem 🌙")}
        </div>
        <div className="popup-subtitle">
          {t(
            "ramadanPromo",
            "Use promo-code “RAMADAN60” for 60% OFF on 3+ items"
          )}
        </div>
        <div className="popup-subtitle-secondary">
          {t("ramadanEid", "Your Eid glow is waiting✨")}
        </div>
        <div className="popup-button-container">
          <button
            className="popup-button"
            type="button"
            onClick={() => {
              handleClosePopup();
              navigate("/products");
            }}
          >
            {t("shopNow", "Shop Now")}
          </button>
        </div>
      </div>
    </>
  );
};

export default RamadanPopup;
