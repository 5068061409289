import React, { useState, useContext } from "react";
import CartContext from "CartContext";
import { useTranslation } from "react-i18next";

const CounterBox = ({
  ProductColor,
  quantity,
  productId,
  onUpdate,
  productLimit,
}) => {
  const [count, setCount] = useState(quantity && quantity > 0 ? quantity : 1);
  const [loadingIncrement, setLoadingIncrement] = useState(false);
  const [loadingDecrement, setLoadingDecrement] = useState(false);
  const { fetchCartCount } = useContext(CartContext);

    // Add this helper function to determine max quantity
    const getMaxQuantity = () => {
      return productId === 6 ? 1 : productLimit;
    };

    // Determine maximum quantity based on product ID
  const maxQuantity = productId === 6 ? 1 : 10;

  const handleIncrement = async () => {
    if (count >= maxQuantity) return;


    const newCount = count + 1;
    setLoadingIncrement(true);
    setLoadingDecrement(true);

    const response = await onUpdate(productId, newCount);

    if (response.message === "Cart updated successfully.") {
      await fetchCartCount();
      setLoadingIncrement(false);
      setLoadingDecrement(false);
      setCount(count + 1);
    } else {
      setLoadingDecrement(false);
      setCount(response.product_limit);
    }
  };

  const handleDecrement = async () => {
    const newCount = count - 1;
    setLoadingIncrement(true);
    setLoadingDecrement(true);
    await onUpdate(productId, newCount);

    if (newCount > 0) {
      await fetchCartCount();
      setLoadingIncrement(false);
      setLoadingDecrement(false);
      setCount(count - 1);
    } else {
      setLoadingIncrement(false);
    }
  };


  return (
    <div
      className="counter-box-cart"
      style={{ border: `1px solid ${ProductColor}` }}
    >
      <div className="counter-container-cart">
        <button
          onClick={handleDecrement}
          disabled={loadingDecrement || count === 1}
          style={{
            color: loadingDecrement || count === 1 ? "gray" : "inherit",
          }}
        >
          -
        </button>
        <span className="counter-value">{count}</span>
        <button
          onClick={handleIncrement}
          disabled={loadingIncrement || count >= maxQuantity}
          style={{
            color: loadingIncrement || count >= maxQuantity ? "gray" : "inherit",
          }}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default CounterBox;
